<template>
  <div class="block-item condition">
    <div class="block-content">
      <span class="condition-status" :class="{ none: branch.none }" @click="handleEditBranch">
        {{ branch.name }}
      </span>
      <slot></slot>
    </div>

    <vuestic-modal v-if="branch" id="if-else-modal" :isOpen="isShowEditBranchModal" @ok="save"
      @cancel="isShowEditBranchModal = false" :cancelShown="false" :force="true" okText="Save" large>
      <span slot="title" class="text-primary font-weight-bold">{{ branch.name }}</span>
      <text-input v-model="branch.name" label="Branch Name" tooltip="Enter name of this specific condition/logic" />
      <div class="branch">
        <div v-if="branch.none" class="row">
          <div class="col-12">
          </div>
        </div>
        <div v-for="(segment, segmentIndex) in branch.segments" :key="`segment-${segmentIndex}`" class="row">
          <div class="col-md-12">
            <div v-for="(condition, conditionIndex) in segment.conditions" :key="`condition-${conditionIndex}`"
              class="row mb-3">
              <div class="col-md-3">
                <div class="control-label">
                  Input
                </div>
                <select v-model="condition.condition" class="form-control">
                  <option value="SMS">Contact Reply</option>
                </select>
              </div>
              <div class="col-md-3">
                <div class="control-label">
                  Operator
                  <a v-b-tooltip.hover title="Select condition logic from drop-down"
                    class="fa fa-question-circle tooltip-icon" />
                </div>
                <select v-model="condition.operator" class="form-control">
                  <option v-for="operator in operators" :key="operator.value" :value="operator.value"
                    :selected="condition.operator === operator.value">{{ operator.label }}</option>
                </select>
              </div>
              <div class="col-md-6 d-flex align-items-center justify-content-between mb-2">
                <text-input v-model="condition.statement" :margin-bottom="0" label="Value"
                  tooltip="Type in condition to make the formula/logic true" style="flex: 1 0 auto;" />
                <button class="btn btn-sm btn-danger ml-2" @click="handleRemoveCondition(segmentIndex, conditionIndex)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
              <div v-if="conditionIndex < segment.conditions.length - 1" class="col-md-12 mt-1">
                <span class="pl-1 font-weight-bold">{{ segment.logic === 'And' ? 'AND' : 'OR' }}</span>
              </div>
            </div>

            <div class="col-md-12 text-right">
              <button v-if="segment.conditions" class="btn btn-sm btn-primary mt-2" @click="addCondition(segment)">
                <i class="fa fa-plus"></i> Add Condition
              </button>
            </div>
          </div>

          <div v-if="segment.conditions && segment.conditions.length > 1" class="col-md-12 mt-1 mb-4">
            <button class="btn btn-sm"
              :class="{ 'btn-primary': segment.logic === 'And', 'btn-outline-primary': segment.logic !== 'And' }"
              @click="handleSetLogic(segment, 'And')">
              AND
            </button>
            <button class="btn btn-sm ml-2"
              :class="{ 'btn-primary': segment.logic === 'Or', 'btn-outline-primary': segment.logic !== 'Or' }"
              @click="handleSetLogic(segment, 'Or')">
              OR
            </button>
          </div>
        </div>
      </div>
    </vuestic-modal>
  </div>
</template>

<script>
export default {
  components: {
  },

  props: {
    branch: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isShowEditBranchModal: false,
      operators: [
        { label: 'Is', value: 'Is' },
        { label: 'Is not', value: 'IsNot' },
        { label: 'Contains', value: 'Contains' },
        { label: 'Does not contain', value: 'DoesNotContain' },
        { label: 'Is not empty', value: 'IsNotEmpty' },
        { label: 'Is empty', value: 'IsEmpty' },
      ],
    }
  },

  computed: {
    durationOptions() {
      return new Array(60)
    }
  },


  methods: {
    handleEditBranch() {
      this.isShowEditBranchModal = true
    },

    save() {
      this.isShowEditBranchModal = false
    },

    addCondition(segment) {
      segment.conditions.push({
        condition: 'SMS',
        operator: 'Is',
        statement: null,
        logic: 'Or',
      })
    },

    handleSetLogic(segment, logic) {
      segment.logic = logic
    },

    handleRemoveCondition(segmentIndex, conditionindex) {
      if (this.branch && this.branch.segments && this.branch.segments[segmentIndex]) {
        this.branch.segments[segmentIndex].conditions.splice(conditionindex, 1)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.branch {
  position: relative;
  padding-top: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}

select {
  width: 100%;
  background: none !important;
  border: .0625rem solid #e7e7e7;
  border-radius: .3rem;
}

div.control-label {
  font-size: 0.6rem;
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;

  .tooltip-icon {
    font-size: 16px;
  }
}</style>